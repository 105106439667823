// @generated by protobuf-ts 2.9.0 with parameter long_type_string
// @generated from protobuf file "rpc/pleasetalk.proto" (package "rpc", syntax proto3)
// tslint:disable
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { PleaseTalk } from "./pleasetalk";
import type { JoinWaitlistResp } from "./pleasetalk";
import type { JoinWaitlistReq } from "./pleasetalk";
import type { ListLanguagesResp } from "./pleasetalk";
import type { ListLanguagesReq } from "./pleasetalk";
import type { RequestMyDataResp } from "./pleasetalk";
import type { RequestMyDataReq } from "./pleasetalk";
import type { DeleteMeResp } from "./pleasetalk";
import type { DeleteMeReq } from "./pleasetalk";
import type { UpdateMeResp } from "./pleasetalk";
import type { UpdateMeReq } from "./pleasetalk";
import type { MeResp } from "./pleasetalk";
import type { MeReq } from "./pleasetalk";
import type { ListUsersResp } from "./pleasetalk";
import type { ListUsersReq } from "./pleasetalk";
import type { SendMessageResp } from "./pleasetalk";
import type { SendMessageReq } from "./pleasetalk";
import type { ListMessagesResp } from "./pleasetalk";
import type { ListMessagesReq } from "./pleasetalk";
import type { ReportConversationResp } from "./pleasetalk";
import type { ReportConversationReq } from "./pleasetalk";
import type { ListConversationsResp } from "./pleasetalk";
import type { ListConversationsReq } from "./pleasetalk";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { AddDeviceResp } from "./pleasetalk";
import type { AddDeviceReq } from "./pleasetalk";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service rpc.PleaseTalk
 */
export interface IPleaseTalkClient {
    /**
     * @generated from protobuf rpc: AddDevice(rpc.AddDeviceReq) returns (rpc.AddDeviceResp);
     */
    addDevice(input: AddDeviceReq, options?: RpcOptions): UnaryCall<AddDeviceReq, AddDeviceResp>;
    /**
     * @generated from protobuf rpc: ListConversations(rpc.ListConversationsReq) returns (rpc.ListConversationsResp);
     */
    listConversations(input: ListConversationsReq, options?: RpcOptions): UnaryCall<ListConversationsReq, ListConversationsResp>;
    /**
     * @generated from protobuf rpc: ReportConversation(rpc.ReportConversationReq) returns (rpc.ReportConversationResp);
     */
    reportConversation(input: ReportConversationReq, options?: RpcOptions): UnaryCall<ReportConversationReq, ReportConversationResp>;
    /**
     * @generated from protobuf rpc: ListMessages(rpc.ListMessagesReq) returns (rpc.ListMessagesResp);
     */
    listMessages(input: ListMessagesReq, options?: RpcOptions): UnaryCall<ListMessagesReq, ListMessagesResp>;
    /**
     * @generated from protobuf rpc: SendMessage(rpc.SendMessageReq) returns (rpc.SendMessageResp);
     */
    sendMessage(input: SendMessageReq, options?: RpcOptions): UnaryCall<SendMessageReq, SendMessageResp>;
    /**
     * @generated from protobuf rpc: ListUsers(rpc.ListUsersReq) returns (rpc.ListUsersResp);
     */
    listUsers(input: ListUsersReq, options?: RpcOptions): UnaryCall<ListUsersReq, ListUsersResp>;
    /**
     * @generated from protobuf rpc: Me(rpc.MeReq) returns (rpc.MeResp);
     */
    me(input: MeReq, options?: RpcOptions): UnaryCall<MeReq, MeResp>;
    /**
     * @generated from protobuf rpc: UpdateMe(rpc.UpdateMeReq) returns (rpc.UpdateMeResp);
     */
    updateMe(input: UpdateMeReq, options?: RpcOptions): UnaryCall<UpdateMeReq, UpdateMeResp>;
    /**
     * Something for GDPR and data management.
     *
     * @generated from protobuf rpc: DeleteMe(rpc.DeleteMeReq) returns (rpc.DeleteMeResp);
     */
    deleteMe(input: DeleteMeReq, options?: RpcOptions): UnaryCall<DeleteMeReq, DeleteMeResp>;
    /**
     * @generated from protobuf rpc: RequestMyData(rpc.RequestMyDataReq) returns (rpc.RequestMyDataResp);
     */
    requestMyData(input: RequestMyDataReq, options?: RpcOptions): UnaryCall<RequestMyDataReq, RequestMyDataResp>;
    /**
     * @generated from protobuf rpc: ListLanguages(rpc.ListLanguagesReq) returns (rpc.ListLanguagesResp);
     */
    listLanguages(input: ListLanguagesReq, options?: RpcOptions): UnaryCall<ListLanguagesReq, ListLanguagesResp>;
    /**
     * Waitlist is temporary available until service will be launched.
     *
     * @generated from protobuf rpc: JoinWaitlist(rpc.JoinWaitlistReq) returns (rpc.JoinWaitlistResp);
     */
    joinWaitlist(input: JoinWaitlistReq, options?: RpcOptions): UnaryCall<JoinWaitlistReq, JoinWaitlistResp>;
}
/**
 * @generated from protobuf service rpc.PleaseTalk
 */
export class PleaseTalkClient implements IPleaseTalkClient, ServiceInfo {
    typeName = PleaseTalk.typeName;
    methods = PleaseTalk.methods;
    options = PleaseTalk.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: AddDevice(rpc.AddDeviceReq) returns (rpc.AddDeviceResp);
     */
    addDevice(input: AddDeviceReq, options?: RpcOptions): UnaryCall<AddDeviceReq, AddDeviceResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<AddDeviceReq, AddDeviceResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListConversations(rpc.ListConversationsReq) returns (rpc.ListConversationsResp);
     */
    listConversations(input: ListConversationsReq, options?: RpcOptions): UnaryCall<ListConversationsReq, ListConversationsResp> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListConversationsReq, ListConversationsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ReportConversation(rpc.ReportConversationReq) returns (rpc.ReportConversationResp);
     */
    reportConversation(input: ReportConversationReq, options?: RpcOptions): UnaryCall<ReportConversationReq, ReportConversationResp> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReportConversationReq, ReportConversationResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListMessages(rpc.ListMessagesReq) returns (rpc.ListMessagesResp);
     */
    listMessages(input: ListMessagesReq, options?: RpcOptions): UnaryCall<ListMessagesReq, ListMessagesResp> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListMessagesReq, ListMessagesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: SendMessage(rpc.SendMessageReq) returns (rpc.SendMessageResp);
     */
    sendMessage(input: SendMessageReq, options?: RpcOptions): UnaryCall<SendMessageReq, SendMessageResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<SendMessageReq, SendMessageResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListUsers(rpc.ListUsersReq) returns (rpc.ListUsersResp);
     */
    listUsers(input: ListUsersReq, options?: RpcOptions): UnaryCall<ListUsersReq, ListUsersResp> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListUsersReq, ListUsersResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: Me(rpc.MeReq) returns (rpc.MeResp);
     */
    me(input: MeReq, options?: RpcOptions): UnaryCall<MeReq, MeResp> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<MeReq, MeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: UpdateMe(rpc.UpdateMeReq) returns (rpc.UpdateMeResp);
     */
    updateMe(input: UpdateMeReq, options?: RpcOptions): UnaryCall<UpdateMeReq, UpdateMeResp> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateMeReq, UpdateMeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Something for GDPR and data management.
     *
     * @generated from protobuf rpc: DeleteMe(rpc.DeleteMeReq) returns (rpc.DeleteMeResp);
     */
    deleteMe(input: DeleteMeReq, options?: RpcOptions): UnaryCall<DeleteMeReq, DeleteMeResp> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteMeReq, DeleteMeResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RequestMyData(rpc.RequestMyDataReq) returns (rpc.RequestMyDataResp);
     */
    requestMyData(input: RequestMyDataReq, options?: RpcOptions): UnaryCall<RequestMyDataReq, RequestMyDataResp> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<RequestMyDataReq, RequestMyDataResp>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: ListLanguages(rpc.ListLanguagesReq) returns (rpc.ListLanguagesResp);
     */
    listLanguages(input: ListLanguagesReq, options?: RpcOptions): UnaryCall<ListLanguagesReq, ListLanguagesResp> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListLanguagesReq, ListLanguagesResp>("unary", this._transport, method, opt, input);
    }
    /**
     * Waitlist is temporary available until service will be launched.
     *
     * @generated from protobuf rpc: JoinWaitlist(rpc.JoinWaitlistReq) returns (rpc.JoinWaitlistResp);
     */
    joinWaitlist(input: JoinWaitlistReq, options?: RpcOptions): UnaryCall<JoinWaitlistReq, JoinWaitlistResp> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<JoinWaitlistReq, JoinWaitlistResp>("unary", this._transport, method, opt, input);
    }
}
