import { TwirpFetchTransport } from '@protobuf-ts/twirp-transport';
import { IPleaseTalkClient, PleaseTalkClient } from "./rpc/pleasetalk.client";
import { RpcOptions, UnaryCall } from "@protobuf-ts/runtime-rpc";
import { Timestamp } from './google/protobuf/timestamp';
import dayjs, { Dayjs } from 'dayjs';

export type TokenFetcher = () => string;

export function newTwirpAPI(tokenFn: TokenFetcher, base_url: string): IPleaseTalkClient {
    if (base_url === undefined || base_url === '') {
        throw new Error("base_url must be provided")
    }

    if (tokenFn === undefined || typeof tokenFn !== 'function') {
        throw new Error('tokenFn must be a function')
    }

    const transport = new TwirpFetchTransport({
        baseUrl: base_url + '/api/',
        sendJson: true,
        interceptors: [
            {
                // adds auth header to unary requests
                interceptUnary(next, method, input, options: RpcOptions): UnaryCall {
                    if (!options.meta) {
                        options.meta = {};
                    }
                    options.meta['Authorization'] = 'Bearer ' + tokenFn();
                    return next(method, input, options);
                }
            }
        ]
    });

    return new PleaseTalkClient(transport);
}

export function TSToDayjs(t: Timestamp | string): Dayjs {
    if (typeof t === 'string') {
        return dayjs(t)
    }

    return dayjs(Timestamp.toDate(t))
} 