// @generated by protobuf-ts 2.9.0 with parameter long_type_string
// @generated from protobuf file "rpc/common.proto" (package "rpc", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../google/protobuf/timestamp";
/**
 * @generated from protobuf message rpc.SharedInfo
 */
export interface SharedInfo {
    /**
     * @generated from protobuf field: rpc.SharedInfo.SharableInfo buddy = 1;
     */
    buddy?: SharedInfo_SharableInfo;
    /**
     * @generated from protobuf field: rpc.SharedInfo.SharableInfo user = 2;
     */
    user?: SharedInfo_SharableInfo;
}
/**
 * @generated from protobuf message rpc.SharedInfo.SharableInfo
 */
export interface SharedInfo_SharableInfo {
    /**
     * @generated from protobuf field: bool real_name = 1;
     */
    realName: boolean;
    /**
     * @generated from protobuf field: bool phone_number = 2;
     */
    phoneNumber: boolean;
}
/**
 * @generated from protobuf message rpc.Conversation
 */
export interface Conversation {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string buddy_id = 2;
     */
    buddyId: string;
    /**
     * @generated from protobuf field: string user_id = 3;
     */
    userId: string;
    /**
     * @generated from protobuf field: rpc.SharedInfo shared_info = 4;
     */
    sharedInfo?: SharedInfo;
    /**
     * @generated from protobuf field: rpc.Conversation.Status status = 5;
     */
    status: Conversation_Status;
}
/**
 * @generated from protobuf enum rpc.Conversation.Status
 */
export enum Conversation_Status {
    /**
     * @generated from protobuf enum value: UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: NORMAL = 1;
     */
    NORMAL = 1,
    /**
     * @generated from protobuf enum value: REPORTED = 2;
     */
    REPORTED = 2
}
/**
 * @generated from protobuf message rpc.User
 */
export interface User {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string username = 2;
     */
    username: string;
    /**
     * @generated from protobuf field: string bio = 3;
     */
    bio: string;
    /**
     * @generated from protobuf field: repeated int32 language_ids = 4;
     */
    languageIds: number[];
    /**
     * @generated from protobuf field: rpc.User.Match match = 5;
     */
    match?: User_Match;
    /**
     * @generated from protobuf field: rpc.User.Contact contact = 6;
     */
    contact?: User_Contact;
}
/**
 * @generated from protobuf message rpc.User.Contact
 */
export interface User_Contact {
    /**
     * @generated from protobuf field: string real_name = 1;
     */
    realName: string;
}
/**
 * @generated from protobuf message rpc.User.Match
 */
export interface User_Match {
    /**
     * @generated from protobuf field: bool as_buddy = 1;
     */
    asBuddy: boolean;
    /**
     * @generated from protobuf field: bool as_user = 2;
     */
    asUser: boolean;
}
/**
 * @generated from protobuf message rpc.Message
 */
export interface Message {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: rpc.Message.Type type = 2;
     */
    type: Message_Type;
    /**
     * @generated from protobuf field: string conversation_id = 3;
     */
    conversationId: string;
    /**
     * @generated from protobuf field: string user_id = 4;
     */
    userId: string;
    /**
     * @generated from protobuf field: string to_id = 5;
     */
    toId: string;
    /**
     * @generated from protobuf field: string text = 6;
     */
    text: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 7;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf enum rpc.Message.Type
 */
export enum Message_Type {
    /**
     * @generated from protobuf enum value: UNDEFINED = 0;
     */
    UNDEFINED = 0,
    /**
     * @generated from protobuf enum value: NORMAL = 1;
     */
    NORMAL = 1,
    /**
     * @generated from protobuf enum value: SYSTEM = 2;
     */
    SYSTEM = 2
}
/**
 * @generated from protobuf message rpc.Language
 */
export interface Language {
    /**
     * @generated from protobuf field: int32 id = 1;
     */
    id: number;
    /**
     * @generated from protobuf field: string description = 2;
     */
    description: string;
}
// @generated message type with reflection information, may provide speed optimized methods
class SharedInfo$Type extends MessageType<SharedInfo> {
    constructor() {
        super("rpc.SharedInfo", [
            { no: 1, name: "buddy", kind: "message", T: () => SharedInfo_SharableInfo },
            { no: 2, name: "user", kind: "message", T: () => SharedInfo_SharableInfo }
        ]);
    }
    create(value?: PartialMessage<SharedInfo>): SharedInfo {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SharedInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SharedInfo): SharedInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rpc.SharedInfo.SharableInfo buddy */ 1:
                    message.buddy = SharedInfo_SharableInfo.internalBinaryRead(reader, reader.uint32(), options, message.buddy);
                    break;
                case /* rpc.SharedInfo.SharableInfo user */ 2:
                    message.user = SharedInfo_SharableInfo.internalBinaryRead(reader, reader.uint32(), options, message.user);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SharedInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rpc.SharedInfo.SharableInfo buddy = 1; */
        if (message.buddy)
            SharedInfo_SharableInfo.internalBinaryWrite(message.buddy, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* rpc.SharedInfo.SharableInfo user = 2; */
        if (message.user)
            SharedInfo_SharableInfo.internalBinaryWrite(message.user, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.SharedInfo
 */
export const SharedInfo = new SharedInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SharedInfo_SharableInfo$Type extends MessageType<SharedInfo_SharableInfo> {
    constructor() {
        super("rpc.SharedInfo.SharableInfo", [
            { no: 1, name: "real_name", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "phone_number", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<SharedInfo_SharableInfo>): SharedInfo_SharableInfo {
        const message = { realName: false, phoneNumber: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SharedInfo_SharableInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SharedInfo_SharableInfo): SharedInfo_SharableInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool real_name */ 1:
                    message.realName = reader.bool();
                    break;
                case /* bool phone_number */ 2:
                    message.phoneNumber = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SharedInfo_SharableInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool real_name = 1; */
        if (message.realName !== false)
            writer.tag(1, WireType.Varint).bool(message.realName);
        /* bool phone_number = 2; */
        if (message.phoneNumber !== false)
            writer.tag(2, WireType.Varint).bool(message.phoneNumber);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.SharedInfo.SharableInfo
 */
export const SharedInfo_SharableInfo = new SharedInfo_SharableInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Conversation$Type extends MessageType<Conversation> {
    constructor() {
        super("rpc.Conversation", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "buddy_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "shared_info", kind: "message", T: () => SharedInfo },
            { no: 5, name: "status", kind: "enum", T: () => ["rpc.Conversation.Status", Conversation_Status] }
        ]);
    }
    create(value?: PartialMessage<Conversation>): Conversation {
        const message = { id: "", buddyId: "", userId: "", status: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Conversation>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Conversation): Conversation {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string buddy_id */ 2:
                    message.buddyId = reader.string();
                    break;
                case /* string user_id */ 3:
                    message.userId = reader.string();
                    break;
                case /* rpc.SharedInfo shared_info */ 4:
                    message.sharedInfo = SharedInfo.internalBinaryRead(reader, reader.uint32(), options, message.sharedInfo);
                    break;
                case /* rpc.Conversation.Status status */ 5:
                    message.status = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Conversation, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string buddy_id = 2; */
        if (message.buddyId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.buddyId);
        /* string user_id = 3; */
        if (message.userId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.userId);
        /* rpc.SharedInfo shared_info = 4; */
        if (message.sharedInfo)
            SharedInfo.internalBinaryWrite(message.sharedInfo, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* rpc.Conversation.Status status = 5; */
        if (message.status !== 0)
            writer.tag(5, WireType.Varint).int32(message.status);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.Conversation
 */
export const Conversation = new Conversation$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User$Type extends MessageType<User> {
    constructor() {
        super("rpc.User", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "username", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "bio", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "language_ids", kind: "scalar", repeat: 1 /*RepeatType.PACKED*/, T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "match", kind: "message", T: () => User_Match },
            { no: 6, name: "contact", kind: "message", T: () => User_Contact }
        ]);
    }
    create(value?: PartialMessage<User>): User {
        const message = { id: "", username: "", bio: "", languageIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User): User {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string username */ 2:
                    message.username = reader.string();
                    break;
                case /* string bio */ 3:
                    message.bio = reader.string();
                    break;
                case /* repeated int32 language_ids */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.languageIds.push(reader.int32());
                    else
                        message.languageIds.push(reader.int32());
                    break;
                case /* rpc.User.Match match */ 5:
                    message.match = User_Match.internalBinaryRead(reader, reader.uint32(), options, message.match);
                    break;
                case /* rpc.User.Contact contact */ 6:
                    message.contact = User_Contact.internalBinaryRead(reader, reader.uint32(), options, message.contact);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string username = 2; */
        if (message.username !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.username);
        /* string bio = 3; */
        if (message.bio !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.bio);
        /* repeated int32 language_ids = 4; */
        if (message.languageIds.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.languageIds.length; i++)
                writer.int32(message.languageIds[i]);
            writer.join();
        }
        /* rpc.User.Match match = 5; */
        if (message.match)
            User_Match.internalBinaryWrite(message.match, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* rpc.User.Contact contact = 6; */
        if (message.contact)
            User_Contact.internalBinaryWrite(message.contact, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.User
 */
export const User = new User$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User_Contact$Type extends MessageType<User_Contact> {
    constructor() {
        super("rpc.User.Contact", [
            { no: 1, name: "real_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<User_Contact>): User_Contact {
        const message = { realName: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User_Contact>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User_Contact): User_Contact {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string real_name */ 1:
                    message.realName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User_Contact, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string real_name = 1; */
        if (message.realName !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.realName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.User.Contact
 */
export const User_Contact = new User_Contact$Type();
// @generated message type with reflection information, may provide speed optimized methods
class User_Match$Type extends MessageType<User_Match> {
    constructor() {
        super("rpc.User.Match", [
            { no: 1, name: "as_buddy", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "as_user", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<User_Match>): User_Match {
        const message = { asBuddy: false, asUser: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<User_Match>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: User_Match): User_Match {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool as_buddy */ 1:
                    message.asBuddy = reader.bool();
                    break;
                case /* bool as_user */ 2:
                    message.asUser = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: User_Match, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool as_buddy = 1; */
        if (message.asBuddy !== false)
            writer.tag(1, WireType.Varint).bool(message.asBuddy);
        /* bool as_user = 2; */
        if (message.asUser !== false)
            writer.tag(2, WireType.Varint).bool(message.asUser);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.User.Match
 */
export const User_Match = new User_Match$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Message$Type extends MessageType<Message> {
    constructor() {
        super("rpc.Message", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["rpc.Message.Type", Message_Type] },
            { no: 3, name: "conversation_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "user_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "to_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Message>): Message {
        const message = { id: "", type: 0, conversationId: "", userId: "", toId: "", text: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Message>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Message): Message {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* rpc.Message.Type type */ 2:
                    message.type = reader.int32();
                    break;
                case /* string conversation_id */ 3:
                    message.conversationId = reader.string();
                    break;
                case /* string user_id */ 4:
                    message.userId = reader.string();
                    break;
                case /* string to_id */ 5:
                    message.toId = reader.string();
                    break;
                case /* string text */ 6:
                    message.text = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 7:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Message, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* rpc.Message.Type type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        /* string conversation_id = 3; */
        if (message.conversationId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.conversationId);
        /* string user_id = 4; */
        if (message.userId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.userId);
        /* string to_id = 5; */
        if (message.toId !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.toId);
        /* string text = 6; */
        if (message.text !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.text);
        /* google.protobuf.Timestamp created_at = 7; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.Message
 */
export const Message = new Message$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Language$Type extends MessageType<Language> {
    constructor() {
        super("rpc.Language", [
            { no: 1, name: "id", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "description", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Language>): Language {
        const message = { id: 0, description: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Language>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Language): Language {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 id */ 1:
                    message.id = reader.int32();
                    break;
                case /* string description */ 2:
                    message.description = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Language, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 id = 1; */
        if (message.id !== 0)
            writer.tag(1, WireType.Varint).int32(message.id);
        /* string description = 2; */
        if (message.description !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.description);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.Language
 */
export const Language = new Language$Type();
