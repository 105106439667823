// @generated by protobuf-ts 2.9.0 with parameter long_type_string
// @generated from protobuf file "rpc/pleasetalk.proto" (package "rpc", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Language } from "./common";
import { User } from "./common";
import { Message } from "./common";
import { Timestamp } from "../google/protobuf/timestamp";
import { Conversation } from "./common";
/**
 * @generated from protobuf message rpc.AddDeviceReq
 */
export interface AddDeviceReq {
    /**
     * @generated from protobuf field: string token = 1;
     */
    token: string;
}
/**
 * @generated from protobuf message rpc.AddDeviceResp
 */
export interface AddDeviceResp {
}
/**
 * @generated from protobuf message rpc.ListConversationsReq
 */
export interface ListConversationsReq {
}
/**
 * @generated from protobuf message rpc.ListConversationsResp
 */
export interface ListConversationsResp {
    /**
     * @generated from protobuf field: repeated rpc.Conversation conversations = 1;
     */
    conversations: Conversation[];
}
/**
 * @generated from protobuf message rpc.ListMessagesReq
 */
export interface ListMessagesReq {
    /**
     * @generated from protobuf field: string conversation_id = 1;
     */
    conversationId: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 2;
     */
    createdAt?: Timestamp;
}
/**
 * @generated from protobuf message rpc.ListMessagesResp
 */
export interface ListMessagesResp {
    /**
     * @generated from protobuf field: repeated rpc.Message messages = 1;
     */
    messages: Message[];
}
/**
 * @generated from protobuf message rpc.ListUsersReq
 */
export interface ListUsersReq {
    /**
     * @generated from protobuf field: repeated string user_ids = 1;
     */
    userIds: string[];
}
/**
 * @generated from protobuf message rpc.ListUsersResp
 */
export interface ListUsersResp {
    /**
     * @generated from protobuf field: repeated rpc.User users = 1;
     */
    users: User[];
}
/**
 * @generated from protobuf message rpc.MeReq
 */
export interface MeReq {
}
/**
 * @generated from protobuf message rpc.MeResp
 */
export interface MeResp {
    /**
     * @generated from protobuf field: rpc.User me = 1;
     */
    me?: User;
}
/**
 * @generated from protobuf message rpc.UpdateMeReq
 */
export interface UpdateMeReq {
    /**
     * @generated from protobuf field: rpc.User me = 1;
     */
    me?: User;
}
/**
 * @generated from protobuf message rpc.UpdateMeResp
 */
export interface UpdateMeResp {
    /**
     * @generated from protobuf field: rpc.User me = 1;
     */
    me?: User;
}
/**
 * @generated from protobuf message rpc.DeleteMeReq
 */
export interface DeleteMeReq {
}
/**
 * @generated from protobuf message rpc.DeleteMeResp
 */
export interface DeleteMeResp {
}
/**
 * @generated from protobuf message rpc.RequestMyDataReq
 */
export interface RequestMyDataReq {
}
/**
 * @generated from protobuf message rpc.RequestMyDataResp
 */
export interface RequestMyDataResp {
}
/**
 * @generated from protobuf message rpc.ReportConversationReq
 */
export interface ReportConversationReq {
    /**
     * @generated from protobuf field: string conversation_id = 1;
     */
    conversationId: string;
}
/**
 * @generated from protobuf message rpc.ReportConversationResp
 */
export interface ReportConversationResp {
}
/**
 * @generated from protobuf message rpc.SendMessageReq
 */
export interface SendMessageReq {
    /**
     * @generated from protobuf field: string conversation_id = 1;
     */
    conversationId: string;
    /**
     * @generated from protobuf field: string text = 2;
     */
    text: string;
}
/**
 * @generated from protobuf message rpc.SendMessageResp
 */
export interface SendMessageResp {
    /**
     * @generated from protobuf field: rpc.Message message = 1;
     */
    message?: Message;
}
/**
 * @generated from protobuf message rpc.ListLanguagesReq
 */
export interface ListLanguagesReq {
}
/**
 * @generated from protobuf message rpc.ListLanguagesResp
 */
export interface ListLanguagesResp {
    /**
     * @generated from protobuf field: repeated rpc.Language languages = 1;
     */
    languages: Language[];
}
/**
 * @generated from protobuf message rpc.JoinWaitlistReq
 */
export interface JoinWaitlistReq {
    /**
     * @generated from protobuf field: string Email = 1 [json_name = "Email"];
     */
    email: string;
    /**
     * @generated from protobuf field: bool IsBuddy = 2 [json_name = "IsBuddy"];
     */
    isBuddy: boolean;
    /**
     * @generated from protobuf field: bool IsBeta = 3 [json_name = "IsBeta"];
     */
    isBeta: boolean;
}
/**
 * @generated from protobuf message rpc.JoinWaitlistResp
 */
export interface JoinWaitlistResp {
}
// @generated message type with reflection information, may provide speed optimized methods
class AddDeviceReq$Type extends MessageType<AddDeviceReq> {
    constructor() {
        super("rpc.AddDeviceReq", [
            { no: 1, name: "token", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<AddDeviceReq>): AddDeviceReq {
        const message = { token: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddDeviceReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddDeviceReq): AddDeviceReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string token */ 1:
                    message.token = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: AddDeviceReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string token = 1; */
        if (message.token !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.token);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.AddDeviceReq
 */
export const AddDeviceReq = new AddDeviceReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class AddDeviceResp$Type extends MessageType<AddDeviceResp> {
    constructor() {
        super("rpc.AddDeviceResp", []);
    }
    create(value?: PartialMessage<AddDeviceResp>): AddDeviceResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<AddDeviceResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: AddDeviceResp): AddDeviceResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: AddDeviceResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.AddDeviceResp
 */
export const AddDeviceResp = new AddDeviceResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListConversationsReq$Type extends MessageType<ListConversationsReq> {
    constructor() {
        super("rpc.ListConversationsReq", []);
    }
    create(value?: PartialMessage<ListConversationsReq>): ListConversationsReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListConversationsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListConversationsReq): ListConversationsReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListConversationsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListConversationsReq
 */
export const ListConversationsReq = new ListConversationsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListConversationsResp$Type extends MessageType<ListConversationsResp> {
    constructor() {
        super("rpc.ListConversationsResp", [
            { no: 1, name: "conversations", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Conversation }
        ]);
    }
    create(value?: PartialMessage<ListConversationsResp>): ListConversationsResp {
        const message = { conversations: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListConversationsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListConversationsResp): ListConversationsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rpc.Conversation conversations */ 1:
                    message.conversations.push(Conversation.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListConversationsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rpc.Conversation conversations = 1; */
        for (let i = 0; i < message.conversations.length; i++)
            Conversation.internalBinaryWrite(message.conversations[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListConversationsResp
 */
export const ListConversationsResp = new ListConversationsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessagesReq$Type extends MessageType<ListMessagesReq> {
    constructor() {
        super("rpc.ListMessagesReq", [
            { no: 1, name: "conversation_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "created_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ListMessagesReq>): ListMessagesReq {
        const message = { conversationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListMessagesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessagesReq): ListMessagesReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string conversation_id */ 1:
                    message.conversationId = reader.string();
                    break;
                case /* google.protobuf.Timestamp created_at */ 2:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessagesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string conversation_id = 1; */
        if (message.conversationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.conversationId);
        /* google.protobuf.Timestamp created_at = 2; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListMessagesReq
 */
export const ListMessagesReq = new ListMessagesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListMessagesResp$Type extends MessageType<ListMessagesResp> {
    constructor() {
        super("rpc.ListMessagesResp", [
            { no: 1, name: "messages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Message }
        ]);
    }
    create(value?: PartialMessage<ListMessagesResp>): ListMessagesResp {
        const message = { messages: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListMessagesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListMessagesResp): ListMessagesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rpc.Message messages */ 1:
                    message.messages.push(Message.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListMessagesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rpc.Message messages = 1; */
        for (let i = 0; i < message.messages.length; i++)
            Message.internalBinaryWrite(message.messages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListMessagesResp
 */
export const ListMessagesResp = new ListMessagesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersReq$Type extends MessageType<ListUsersReq> {
    constructor() {
        super("rpc.ListUsersReq", [
            { no: 1, name: "user_ids", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListUsersReq>): ListUsersReq {
        const message = { userIds: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUsersReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersReq): ListUsersReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string user_ids */ 1:
                    message.userIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string user_ids = 1; */
        for (let i = 0; i < message.userIds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.userIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListUsersReq
 */
export const ListUsersReq = new ListUsersReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListUsersResp$Type extends MessageType<ListUsersResp> {
    constructor() {
        super("rpc.ListUsersResp", [
            { no: 1, name: "users", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => User }
        ]);
    }
    create(value?: PartialMessage<ListUsersResp>): ListUsersResp {
        const message = { users: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListUsersResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListUsersResp): ListUsersResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rpc.User users */ 1:
                    message.users.push(User.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListUsersResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rpc.User users = 1; */
        for (let i = 0; i < message.users.length; i++)
            User.internalBinaryWrite(message.users[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListUsersResp
 */
export const ListUsersResp = new ListUsersResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeReq$Type extends MessageType<MeReq> {
    constructor() {
        super("rpc.MeReq", []);
    }
    create(value?: PartialMessage<MeReq>): MeReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeReq): MeReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.MeReq
 */
export const MeReq = new MeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MeResp$Type extends MessageType<MeResp> {
    constructor() {
        super("rpc.MeResp", [
            { no: 1, name: "me", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<MeResp>): MeResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MeResp): MeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rpc.User me */ 1:
                    message.me = User.internalBinaryRead(reader, reader.uint32(), options, message.me);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rpc.User me = 1; */
        if (message.me)
            User.internalBinaryWrite(message.me, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.MeResp
 */
export const MeResp = new MeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMeReq$Type extends MessageType<UpdateMeReq> {
    constructor() {
        super("rpc.UpdateMeReq", [
            { no: 1, name: "me", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<UpdateMeReq>): UpdateMeReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateMeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMeReq): UpdateMeReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rpc.User me */ 1:
                    message.me = User.internalBinaryRead(reader, reader.uint32(), options, message.me);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rpc.User me = 1; */
        if (message.me)
            User.internalBinaryWrite(message.me, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.UpdateMeReq
 */
export const UpdateMeReq = new UpdateMeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateMeResp$Type extends MessageType<UpdateMeResp> {
    constructor() {
        super("rpc.UpdateMeResp", [
            { no: 1, name: "me", kind: "message", T: () => User }
        ]);
    }
    create(value?: PartialMessage<UpdateMeResp>): UpdateMeResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateMeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateMeResp): UpdateMeResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rpc.User me */ 1:
                    message.me = User.internalBinaryRead(reader, reader.uint32(), options, message.me);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateMeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rpc.User me = 1; */
        if (message.me)
            User.internalBinaryWrite(message.me, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.UpdateMeResp
 */
export const UpdateMeResp = new UpdateMeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMeReq$Type extends MessageType<DeleteMeReq> {
    constructor() {
        super("rpc.DeleteMeReq", []);
    }
    create(value?: PartialMessage<DeleteMeReq>): DeleteMeReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteMeReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMeReq): DeleteMeReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteMeReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.DeleteMeReq
 */
export const DeleteMeReq = new DeleteMeReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteMeResp$Type extends MessageType<DeleteMeResp> {
    constructor() {
        super("rpc.DeleteMeResp", []);
    }
    create(value?: PartialMessage<DeleteMeResp>): DeleteMeResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DeleteMeResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteMeResp): DeleteMeResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: DeleteMeResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.DeleteMeResp
 */
export const DeleteMeResp = new DeleteMeResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestMyDataReq$Type extends MessageType<RequestMyDataReq> {
    constructor() {
        super("rpc.RequestMyDataReq", []);
    }
    create(value?: PartialMessage<RequestMyDataReq>): RequestMyDataReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestMyDataReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestMyDataReq): RequestMyDataReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RequestMyDataReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.RequestMyDataReq
 */
export const RequestMyDataReq = new RequestMyDataReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RequestMyDataResp$Type extends MessageType<RequestMyDataResp> {
    constructor() {
        super("rpc.RequestMyDataResp", []);
    }
    create(value?: PartialMessage<RequestMyDataResp>): RequestMyDataResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<RequestMyDataResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RequestMyDataResp): RequestMyDataResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: RequestMyDataResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.RequestMyDataResp
 */
export const RequestMyDataResp = new RequestMyDataResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportConversationReq$Type extends MessageType<ReportConversationReq> {
    constructor() {
        super("rpc.ReportConversationReq", [
            { no: 1, name: "conversation_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReportConversationReq>): ReportConversationReq {
        const message = { conversationId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportConversationReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportConversationReq): ReportConversationReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string conversation_id */ 1:
                    message.conversationId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReportConversationReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string conversation_id = 1; */
        if (message.conversationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.conversationId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ReportConversationReq
 */
export const ReportConversationReq = new ReportConversationReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReportConversationResp$Type extends MessageType<ReportConversationResp> {
    constructor() {
        super("rpc.ReportConversationResp", []);
    }
    create(value?: PartialMessage<ReportConversationResp>): ReportConversationResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ReportConversationResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReportConversationResp): ReportConversationResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ReportConversationResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ReportConversationResp
 */
export const ReportConversationResp = new ReportConversationResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendMessageReq$Type extends MessageType<SendMessageReq> {
    constructor() {
        super("rpc.SendMessageReq", [
            { no: 1, name: "conversation_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "text", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SendMessageReq>): SendMessageReq {
        const message = { conversationId: "", text: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendMessageReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendMessageReq): SendMessageReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string conversation_id */ 1:
                    message.conversationId = reader.string();
                    break;
                case /* string text */ 2:
                    message.text = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendMessageReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string conversation_id = 1; */
        if (message.conversationId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.conversationId);
        /* string text = 2; */
        if (message.text !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.text);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.SendMessageReq
 */
export const SendMessageReq = new SendMessageReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SendMessageResp$Type extends MessageType<SendMessageResp> {
    constructor() {
        super("rpc.SendMessageResp", [
            { no: 1, name: "message", kind: "message", T: () => Message }
        ]);
    }
    create(value?: PartialMessage<SendMessageResp>): SendMessageResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<SendMessageResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SendMessageResp): SendMessageResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* rpc.Message message */ 1:
                    message.message = Message.internalBinaryRead(reader, reader.uint32(), options, message.message);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SendMessageResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* rpc.Message message = 1; */
        if (message.message)
            Message.internalBinaryWrite(message.message, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.SendMessageResp
 */
export const SendMessageResp = new SendMessageResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLanguagesReq$Type extends MessageType<ListLanguagesReq> {
    constructor() {
        super("rpc.ListLanguagesReq", []);
    }
    create(value?: PartialMessage<ListLanguagesReq>): ListLanguagesReq {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListLanguagesReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLanguagesReq): ListLanguagesReq {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ListLanguagesReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListLanguagesReq
 */
export const ListLanguagesReq = new ListLanguagesReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListLanguagesResp$Type extends MessageType<ListLanguagesResp> {
    constructor() {
        super("rpc.ListLanguagesResp", [
            { no: 1, name: "languages", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Language }
        ]);
    }
    create(value?: PartialMessage<ListLanguagesResp>): ListLanguagesResp {
        const message = { languages: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ListLanguagesResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListLanguagesResp): ListLanguagesResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated rpc.Language languages */ 1:
                    message.languages.push(Language.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListLanguagesResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated rpc.Language languages = 1; */
        for (let i = 0; i < message.languages.length; i++)
            Language.internalBinaryWrite(message.languages[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.ListLanguagesResp
 */
export const ListLanguagesResp = new ListLanguagesResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinWaitlistReq$Type extends MessageType<JoinWaitlistReq> {
    constructor() {
        super("rpc.JoinWaitlistReq", [
            { no: 1, name: "Email", kind: "scalar", jsonName: "Email", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "IsBuddy", kind: "scalar", jsonName: "IsBuddy", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "IsBeta", kind: "scalar", jsonName: "IsBeta", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<JoinWaitlistReq>): JoinWaitlistReq {
        const message = { email: "", isBuddy: false, isBeta: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JoinWaitlistReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinWaitlistReq): JoinWaitlistReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string Email = 1 [json_name = "Email"];*/ 1:
                    message.email = reader.string();
                    break;
                case /* bool IsBuddy = 2 [json_name = "IsBuddy"];*/ 2:
                    message.isBuddy = reader.bool();
                    break;
                case /* bool IsBeta = 3 [json_name = "IsBeta"];*/ 3:
                    message.isBeta = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: JoinWaitlistReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string Email = 1 [json_name = "Email"]; */
        if (message.email !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.email);
        /* bool IsBuddy = 2 [json_name = "IsBuddy"]; */
        if (message.isBuddy !== false)
            writer.tag(2, WireType.Varint).bool(message.isBuddy);
        /* bool IsBeta = 3 [json_name = "IsBeta"]; */
        if (message.isBeta !== false)
            writer.tag(3, WireType.Varint).bool(message.isBeta);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.JoinWaitlistReq
 */
export const JoinWaitlistReq = new JoinWaitlistReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class JoinWaitlistResp$Type extends MessageType<JoinWaitlistResp> {
    constructor() {
        super("rpc.JoinWaitlistResp", []);
    }
    create(value?: PartialMessage<JoinWaitlistResp>): JoinWaitlistResp {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<JoinWaitlistResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: JoinWaitlistResp): JoinWaitlistResp {
        return target ?? this.create();
    }
    internalBinaryWrite(message: JoinWaitlistResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message rpc.JoinWaitlistResp
 */
export const JoinWaitlistResp = new JoinWaitlistResp$Type();
/**
 * @generated ServiceType for protobuf service rpc.PleaseTalk
 */
export const PleaseTalk = new ServiceType("rpc.PleaseTalk", [
    { name: "AddDevice", options: {}, I: AddDeviceReq, O: AddDeviceResp },
    { name: "ListConversations", options: {}, I: ListConversationsReq, O: ListConversationsResp },
    { name: "ReportConversation", options: {}, I: ReportConversationReq, O: ReportConversationResp },
    { name: "ListMessages", options: {}, I: ListMessagesReq, O: ListMessagesResp },
    { name: "SendMessage", options: {}, I: SendMessageReq, O: SendMessageResp },
    { name: "ListUsers", options: {}, I: ListUsersReq, O: ListUsersResp },
    { name: "Me", options: {}, I: MeReq, O: MeResp },
    { name: "UpdateMe", options: {}, I: UpdateMeReq, O: UpdateMeResp },
    { name: "DeleteMe", options: {}, I: DeleteMeReq, O: DeleteMeResp },
    { name: "RequestMyData", options: {}, I: RequestMyDataReq, O: RequestMyDataResp },
    { name: "ListLanguages", options: {}, I: ListLanguagesReq, O: ListLanguagesResp },
    { name: "JoinWaitlist", options: {}, I: JoinWaitlistReq, O: JoinWaitlistResp }
]);
